.SharedCollection[data-v-25deb55c] {
  background: #F8F8F8;
  height: 100%;
  padding: 32px 0 0;
}
.Collection[data-v-25deb55c] {
  max-width: 448px;
  margin: 0 auto;
  min-height: calc(100vh - 32px);
  background: white;
  padding-bottom: 162px;
  border-radius: 12px 12px 0 0;
}
.CollectionHeader[data-v-25deb55c] {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 16px;
}
.CollectionPreview[data-v-25deb55c] {
  width: 144px;
  height: 144px;
  margin: 0 auto 20px;
  background-size: cover;
  border-radius: 12px;
  background-color: gray;
}
.CollectionTitle[data-v-25deb55c] {
  font-size: 32px;
  line-height: 34px;
  font-weight: bold;
  margin: 10px 30px;
}
.CollectionMeta[data-v-25deb55c] {
  font-size: 14px;
  line-height: 26px;
  margin: 10px 30px;
  opacity: 0.45;
}
.CollectionTerm[data-v-25deb55c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /*&:hover {
        background: yellow;
    }*/
}
.CollectionTerm + .CollectionTerm[data-v-25deb55c] {
  border-top: 1px solid #F1F1F1;
}
.Term[data-v-25deb55c],
.Definition[data-v-25deb55c] {
  width: 50%;
  font-size: 15px;
  line-height: 20px;
}
.Term[data-v-25deb55c] {
  padding: 17px 16px 19px 32px;
}
.Definition[data-v-25deb55c] {
  padding: 17px 32px 19px 16px;
  opacity: 0.4;
}
.ButtonWrapper[data-v-25deb55c] {
  position: fixed;
  width: 448px;
  left: 50%;
  margin-left: calc(-448px/2);
  bottom: 0;
  padding: 0 16px 16px;
}
.ButtonWrapper[data-v-25deb55c]:before {
  content: ' ';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: -98px;
  background: rgba(255, 255, 255, 0);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#ffffff));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  pointer-events: none;
  z-index: -1;
}
.BlackButton[data-v-25deb55c] {
  display: block;
  background: black;
  width: 100%;
  color: white;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
  padding: 11px 0 9px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}
.BlackButton[data-v-25deb55c]:hover {
  opacity: 0.7;
}
.BlackButton[data-v-25deb55c]:disabled {
  opacity: 0.5;
}
.BlackButton.accepted[data-v-25deb55c] {
  pointer-events: none;
}
