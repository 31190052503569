.ModelPage[data-v-04662614] {
  padding-top: 100px;
}
.ModelPage .LeftColumn[data-v-04662614] {
  margin-right: 500px;
}
.ModelPage .RightColumn[data-v-04662614] {
  width: 500px;
  background: white;
  border-left: 1px solid #f0f0f0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 40px;
}
.Params summary[data-v-04662614] {
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ParamsList[data-v-04662614] {
  padding-top: 20px;
}
.Param[data-v-04662614] {
  min-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.Param .ParamName[data-v-04662614] {
  width: 200px;
  padding-right: 10px;
}
.Param .ParamValue[data-v-04662614] {
  width: 140px;
}
.Param .ParamInput[data-v-04662614] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
