.Signup--popup[data-v-2dc5f4d2] {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background: white;
}
.SignupContainer[data-v-2dc5f4d2] {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 254px;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.SignupLogo[data-v-2dc5f4d2] {
  margin-bottom: 25px;
}
.SignupLogo svg[data-v-2dc5f4d2] {
  width: 32px;
  height: 32px;
  vertical-align: top;
}
.SignupLogo span[data-v-2dc5f4d2] {
  margin-top: 3px;
  margin-left: 5px;
  font-size: 23px;
  display: inline-block;
  font-weight: 700;
}
.SignupForm[data-v-2dc5f4d2] {
  width: 100%;
  position: relative;
}
.SignupForm input[data-v-2dc5f4d2] {
  background: #F9F9F9;
  font-size: 14px;
  color: black;
  border: 1px solid #E6E6E6;
  display: block;
  width: 100%;
  padding: 10px 16px;
  outline: none;
  margin-bottom: 9px;
  border-radius: 12px;
}
.SignupForm button[data-v-2dc5f4d2] {
  display: block;
  width: 130px;
  height: 40px;
  border-radius: 20px;
  background: #4A90E2;
  font-size: 16px;
  color: white;
  cursor: pointer;
  margin-top: 20px;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.SignupForm button[data-v-2dc5f4d2]:disabled {
  background: #CDCDCD;
}
.SignupForm button[data-v-2dc5f4d2]:hover:not(:disabled) {
  opacity: 0.7;
}
.SignupError[data-v-2dc5f4d2] {
  position: absolute;
  left: 100%;
  color: #fff;
  top: 45px;
  margin-left: 30px;
  text-align: center;
  font-size: 16px;
  padding: 15px;
  background: #eb1e32;
  border-radius: 12px;
  min-width: 200px;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
}
.SignupError--visible[data-v-2dc5f4d2] {
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 1;
  pointer-events: all;
}
